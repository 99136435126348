import {attr, controller, target} from '@github/catalyst'
import type {FileTreeElement} from '../../../../components/pull_requests/file_tree/file-tree-element'
import {debounce} from '@github/mini-throttle/decorators'

@controller
export class DiffLayoutElement extends HTMLElement {
  static attrPrefix = ''
  @attr sidebarHidden = false

  @target layoutContainer: HTMLElement
  @target diffToolbar: HTMLElement
  @target tocMenu: HTMLElement
  @target tocMenuButton: HTMLElement
  @target mainContainer: HTMLElement
  @target sidebarContainer: HTMLElement | undefined
  @target fileTree: FileTreeElement | undefined
  @target showFileTreeButton: HTMLButtonElement | undefined
  @target fileTreePathFilter: HTMLInputElement | undefined
  #scrollTimer: number | null = null
  #resizeTimer: number | null = null
  #eventAbortController: AbortController | null
  #notificationShelfObserver: MutationObserver

  connectedCallback() {
    if (!this.sidebarContainer) return

    this.#notificationShelfObserver = new MutationObserver(this.handleNotificationShelfMutation)
    this.#notificationShelfObserver.observe(document.body, {subtree: true, childList: true})

    const {signal} = (this.#eventAbortController = new AbortController())

    // We're unable to use IntersectionObserver because the sidebar element never
    // intersects with another element or the document viewport, even on scroll.
    /* eslint-disable-next-line github/prefer-observers */
    window.addEventListener('scroll', this.handleSidebarScroll, {signal})

    // We're unable to use ResizeObserver because the document is vertically scrollable
    // and its height never actually changes, so the ResizeObserver callback never fires
    // on window height changes.
    /* eslint-disable-next-line github/prefer-observers */
    window.addEventListener('resize', () => this.handleSidebarResize(), {signal})
    this.handleSidebarScroll()
  }

  disconnectedCallback() {
    if (!this.sidebarContainer) return
    this.#notificationShelfObserver.disconnect()
    this.#eventAbortController?.abort()
    if (this.#scrollTimer != null) cancelAnimationFrame(this.#scrollTimer)
    if (this.#resizeTimer != null) cancelAnimationFrame(this.#resizeTimer)
  }

  toggleSidebar(event: CustomEvent): void {
    this.layoutContainer.classList.toggle('hx_Layout--sidebar-hidden')
    this.sidebarHidden = !this.sidebarHidden
    if (!this.fileTree) return

    const toggleButton = event.detail.toggleButton
    if (!toggleButton) return

    this.fileTree.instrumentToggleFileTree(toggleButton)

    if (!this.fileTreePathFilter) return
    if (toggleButton.id === 'show-file-tree-button') this.fileTreePathFilter.focus()
  }

  // The handler function below is a workaround that allows the "t" keyboard shortcut to control
  // multiple elements on the `pr#files` page: the file tree toggle button (when visible) and the
  // 'Jump to' menu button (when visible). Normally, we would add the hotkey listener to each of
  // these buttons directly, but we can't in this case, because the `@github-ui/hotkey` library only
  // supports mapping a keyboard shortcut to a single element on the page, regardless of whether
  // that element is currently visible.
  handleOpenFilesListHotkeyEvent(): void {
    if (this.shouldOpenTocMenu()) {
      this.tocMenuButton.click()
      return
    }

    if (!this.fileTree) return

    if (this.showFileTreeButton && this.sidebarHidden) {
      this.showFileTreeButton.click()
    } else if (this.fileTreePathFilter && !this.sidebarHidden) {
      this.fileTreePathFilter.focus()
    }
  }

  shouldOpenTocMenu(): boolean {
    return !!(
      this.tocMenu &&
      !this.tocMenu.hasAttribute('open') &&
      window.getComputedStyle(this.tocMenu).display !== 'none'
    )
  }

  handleNotificationShelfMutation(mutations: MutationRecord[]): void {
    const root = <HTMLHtmlElement>document.firstElementChild!
    if (!root.classList.contains('js-skip-scroll-target-into-view')) return

    for (const mutation of mutations) {
      for (const addedNode of mutation.addedNodes) {
        if (!(addedNode instanceof HTMLElement)) continue
        if (addedNode.classList.contains('js-notification-top-shelf')) {
          root.classList.add('has-notification-top-shelf')
          break
        }
      }
      for (const removedNode of mutation.removedNodes) {
        if (!(removedNode instanceof HTMLElement)) continue
        if (removedNode.classList.contains('js-notification-top-shelf')) {
          root.classList.remove('has-notification-top-shelf')
          break
        }
      }
    }
  }

  @debounce(100)
  handleSidebarResize(): void {
    this.#resizeTimer = requestAnimationFrame(this.updateSidebarHeight)
  }

  handleSidebarScroll = () => {
    this.#scrollTimer = requestAnimationFrame(this.updateSidebarHeight)
  }

  updateSidebarHeight = () => {
    if (!this.sidebarContainer || !this.diffToolbar) return
    const viewportHeight = document.documentElement.clientHeight
    const diffToolbarRect = this.diffToolbar.getBoundingClientRect()
    const mainRect = this.mainContainer.getBoundingClientRect()

    const scrolledToEnd: boolean = viewportHeight >= mainRect.bottom
    const toolbarStuck: boolean = diffToolbarRect.top === 0

    let sidebarHeight: number

    if (scrolledToEnd) {
      sidebarHeight = mainRect.bottom - parseInt(this.sidebarContainer.style.top)
      sidebarHeight = Math.min(mainRect.height, sidebarHeight)
    } else if (toolbarStuck) {
      sidebarHeight = viewportHeight - diffToolbarRect.height
    } else {
      // scroll at top
      sidebarHeight = viewportHeight - this.sidebarContainer.getBoundingClientRect().top
    }

    this.sidebarContainer.style.height = `${sidebarHeight}px`
  }
}
